// External Dependacies
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "leaflet/dist/leaflet.css";

// Internal Dependency
import Map from "../components/map";
import "../assets/css/contactus.css";

const ContactUs = () => {
  return (
    <div>
      <div className='vlines vlines--dashed vlines--light'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className='contact_us_border'>
        <div className='contact_border'>
          <p>
            <span className='border_green'></span> Contact Us{" "}
            <span className='border_red'></span>{" "}
          </p>
        </div>
        <div className='contact_us'>
          <div className='map_container'>
            <Map />
          </div>
          <div className='contact_card animate__fadeInRight'>
            <div className='contact_information'>
              <p>For more information or enquiry,walk into our office or call:</p>
            </div>
            <div className='contact_icon_container'>
              <div className='contact_icon'>
                <div>
                  <FontAwesomeIcon
                    className='contact_icon_awesome'
                    icon={faLocationDot}
                  />
                </div>
                <div>
                  <p className='contact_office'>Head Office</p>
                  <p className='contact_address'>
                    21 Old Aba Road, Port Harcourt, Rivers State, Nigeria.
                  </p>
                </div>
              </div>
              <div className='contact_icon'>
                <div>
                  <FontAwesomeIcon
                    className='contact_icon_awesome'
                    icon={faEnvelopeOpen}
                  />
                </div>
                <div>
                  <p className='contact_office'>Email Address</p>
                  <p className='contact_address'>info@imasslimited.com</p>
                </div>
              </div>
              <div className='contact_icon'>
                <div>
                  <FontAwesomeIcon className='contact_icon_awesome' icon={faPhone} />
                </div>
                <div>
                  <p className='contact_office'>Phone Number</p>
                  <p className='contact_address'>+234 803 846 9784</p>
                  <p> +234902 898 2819</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
