import React from "react";
import "../assets/css/whatwedo.css";
import Card from "./card";
import Selection from "./selection";

const Whatwedo = () => {
  return (
    <div className="whatwedo_container">
      <div className="whatwedo_head">
        <p>
          {" "}
          <span className="whatred_short"></span> What We Do{" "}
          <span className="whatred_long"></span>
        </p>
      </div>
      <div className="forcastCard">
        {Selection.map((selectionItem) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <Card
              className="whatwedo_card"
              image={selectionItem.image}
              header={selectionItem.header}
              body={selectionItem.body}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Whatwedo;
