import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/header.css";
import { FaBars } from "react-icons/fa";
// import { useRef } from "react";
import imasslogo from "../assets/images/imasslogo.png";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const menuItem = document.querySelectorAll("menu-item");
  menuItem.forEach((item) => {
    item.addEventListener("click", () => {});
  });
  return (
    <nav className='navbar'>
      <div className='container'>
        <div className='logo'>
          <img src={imasslogo} alt='Kayndrexlogo' />
        </div>
        <div className='menu-icon' onClick={handleShowNavbar}>
          <FaBars className='side_bar' />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <Link onClick={handleShowNavbar} to='home'>
                Home
              </Link>
            </li>
            <li>
              <Link onClick={handleShowNavbar} to='aboutus'>
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={handleShowNavbar} to='whatwedo'>
                What We Do
              </Link>
            </li>
            <li>
              <Link onClick={handleShowNavbar} to='contactus'>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
