import React from "react";
import "../assets/css/aboutus.css";
import AboutusGraphic from "../assets/images/shipImage2.png";
import imassbiglogo from "../assets/images/Imassbiglogo.png";

const Aboutus = () => {
  return (
    <div className='aboutus_image'>
      <div className='imassltd_logo'>
        <div className='black_bg'></div>
      </div>
      <div className='about_us'>
        <div className='about_border'>
          <p>
            <span className='red_border'></span>
            <span className='aboutus_header'>About Us</span>
            <span className='red_borderlong'></span>
          </p>
        </div>
        <div className='aboutus_talk'>
          <div className='aboutus_imass'>
            <p>
              <span>Intercontinental Maritime and Subsea Support Limited </span>
              was incorporated in 2018 and it is a full indigenous company with expertise
              from diving, Subsea Maintenance, Marine Logistics, marine transportation,
              engineering, management and Human Resources backgrounds in Oil and Gas,
              Maritime, Constructions, Environmental and Petroleum processes in Nigeria
              Companies.
            </p>
            <p>
              In the relatively short period, we have established ourselves to compete
              with major companies already in existence. It has established a good working
              practice to ensure that the Company set objectives is followed to the
              latter.
            </p>
            <p>
              Intercontinental Maritime and Subsea Support Limited was organized to be
              responsive to the emerging new technologies in our industries by constantly
              monitoring new technologies that will be fitted to the needs of our clients,
              establish new product lines for value added services of the service
              providers.
            </p>
            <p>
              In so doing, the company has also employed highly technical, well
              disciplined, foreign trained and locally trained, field tested and
              experienced manpower to handle all of our operational jobs with a
              professional&#39;s touch. The unending cross training among each of the
              experienced employees has further enriched the company&#39;s readiness to
              meet the demand of the future and readiness to serve the needs of its valued
              Clients within the country.
            </p>
          </div>
          <div className='aboutus_image'>
            <img src={imassbiglogo} alt='IMASS' />
          </div>
        </div>
      </div>
      <div className='vision_container'>
        <div className='vision_mission'>
          <div className='aboutus_graphic'>
            <img src={AboutusGraphic} alt='Aboutus Graphic' />
          </div>
          <div className='our_vision'>
            <h1>Our Vision</h1>
            <p>
              To be the nation&#39;s leader and sought-after professional agent in Marine,
              Subsea Services and Maritime equipment in whatever form and substance, to
              serve the various needs of the growing industry utilizing the
              state-of-the-art, 21st century technology
            </p>
          </div>
          <div className='our_vision our_mission'>
            <h1>Our Mission</h1>
            <p>
              {" "}
              To be part of a success story by providing efficient professional services
              and technical support to ensure fast and effective response to the industry
              requirements.
            </p>
          </div>
        </div>
        <div className='our_team'>
          <p>
            Our team of experts work round the clock to ensure our clients experience
            exceptional service and value.
          </p>
        </div>
      </div>
      <div className='monti_blue'>
        <h3>IMASS,</h3>
        <p>
          We serve your every <span className='martime_need'>maritime</span> need.
        </p>
      </div>
    </div>
  );
};
export default Aboutus;
