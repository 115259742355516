import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import "../assets/css/contactus.css";
import locationIcon from "../assets/icons/location.svg";

const myIcon = Leaflet.icon({
  iconUrl: locationIcon,
  iconSize: [60, 60],
});

const Map = () => {
  return (
    <div>
      <MapContainer
        className="map"
        center={[4.8439383, 7.0409827]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[4.8439383, 7.0409827]} icon={myIcon}></Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
