import React from "react";
import CustomButton from "../library/CustomButton";
import "../assets/css/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { EmblaCarousel } from "./emblacarousel";
import { Link } from "react-router-dom";
import "../assets/css/contactus.css";
import shipsvg from "../assets/images/shipdrawings.svg";

const Home = () => {
  return (
    <div className='home_container'>
      <div className='home_construction'>
        <div className='construction_need'>
          <p className='borderblue_short'> </p>
          <p className='monti_ng'>
            We are Intercontinental Maritime and Subsea Support Limited
          </p>
          <h3 className='serve_every'>Serving your every</h3>
          <h1 className='construction_serve'>Maritime Need</h1>
          <p className='borderblue_long'> </p>
        </div>
        <div className='construction_image'>
          <EmblaCarousel />
        </div>
      </div>
      <div>
        <div className='vlines vlines--dashed vlines--light'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className='imass_ship'>
          <div className='imass_image'>
            <div className='imass_ltd'>
              <p>
                <span className='monti_ltd'>
                  Intercontinental Maritime and Subsea Limited
                </span>{" "}
                was organized to be responsive to the emerging new technologies in our
                industries by constantly monitoring new technologies that will be fitted
                to the needs of our clients, establish new product lines for value added
                services of the service providers.
              </p>
              <p>
                {" "}
                In so doing, the company has alos emloyed highly technical, well
                disciplined, foreign trained and loaclly trained , field tested and
                experienced manpower to handle all of our operational jobs with a
                professional&#39;s touch ...{" "}
              </p>
            </div>
            <div className='read_more'>
              <Link to={"aboutus"}>
                <CustomButton
                  buttonColor='#011947'
                  buttonHeight='3rem'
                  buttonWidth='10rem'>
                  Read More{" "}
                  <FontAwesomeIcon className='arrow_right' icon={faArrowRight} />
                </CustomButton>
              </Link>
            </div>
          </div>
          <div className='ship_drawing'>
            <img src={shipsvg} alt='shipdrawing svg' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
