import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import vessel5 from "../assets/images/vessels5.jpeg";
import vessel6 from "../assets/images/leadz.png";
import vessels7 from "../assets/images/vessels7.jpeg";
import vessel3 from "../assets/images/vessel3.jpeg";
import vessel4 from "../assets/images/vessel4.jpeg";
import vessel1 from "../assets/images/leaderz.jpeg";
import "../assets/css/home.css";

const autoplayOptions = {
  delay: 6000,
  jump: true,
  rootNode: (emblaRoot) => emblaRoot.parentElement,
};

export const EmblaCarousel = () => {
  const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay(autoplayOptions)]);

  return (
    <div className='embla' ref={emblaRef}>
      <div className='embla__container'>
        <div className='embla__slide'>
          <img src={vessel1} alt='service propeerty' />
        </div>
        <div className='embla__slide'>
          <img src={vessel6} alt='service propeerty' />
        </div>
        <div className='embla__slide'>
          <img src={vessel5} alt='service propeerty' />
        </div>
        <div className='embla__slide'>
          <img src={vessels7} alt='service propeerty' />
        </div>
        <div className='embla__slide'>
          <img src={vessel3} alt='service propeerty' />
        </div>
        <div className='embla__slide'>
          <img src={vessel4} alt='service propeerty' />
        </div>
      </div>
    </div>
  );
};
