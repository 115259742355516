
import vessel2 from "../assets/images/vessel2.jpeg";
import vessels5 from "../assets/images/vessels5.jpeg"

const Selection = [
    {
        id:1,
        image: vessel2,
        header: "BERTHING AND MOORING",
        body: "Consequat Lorem tempor Lorem officia reprehenderit laboris incididunt eiusmod id Lorem nostrud."
    },
    {
        id:2,
        image: vessels5,
        header: "MARINE SERVICES",
        body: "Magna quis laborum nulla incididunt cillum laboris deserunt aliqua non proident. Sunt et ut et aliqua consequat nulla fugiat mollit est irure nisi deserunt esse esse. Dolore magna quis sunt ea non Lorem. Incididunt velit voluptate do proident et proident nostrud sit voluptate. Eu qui non qui quis adipisicing non non eiusmod fugiat exercitation officia do consequat reprehenderit. Eu id aliqua magna enim duis ad nisi aliquip. Esse anim minim est tempor ea eu sunt do nostrud aliqua proident ex minim."
    },
    
]

export default Selection;