/* eslint-disable react/react-in-jsx-scope */
import './App.css';
import Layout from './components/layout';
import Home from './components/home';
import Aboutus from './components/aboutus';
import Whatwedo from './components/whatwedo';
import ContactUs from './components/contactus';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';



function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Layout/>} >
          <Route path="home" element={<Home />} />
          <Route exact path="aboutus" element={<Aboutus />} />
          <Route path="home/aboutus" element={<Aboutus/>}/>
          <Route exact path="whatwedo" element={<Whatwedo />} />
          <Route exact path="contactus" element={<ContactUs />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
          
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
