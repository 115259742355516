import React from "react";
import '../assets/css/footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopyright } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import PhLogo  from "../assets/images/phgov.jpeg";
import vessel2 from "../assets/images/vessel2.jpeg";
import CustomButton from "../library/CustomButton";
import { Link } from "react-router-dom";




const Footer = () => {
  return (
    <div className="footer_container">
        {/* <div className="our_clients">
            <div >
                <h4 className="clients_cat"><span>•</span>Our Clients<span>•</span></h4>
                <p>Ullamco magna quis consectetur id qui aliquip laborum excepteur mollit.<br/>Ipsum aliquip reprehenderit officia Lorem laborum sint.</p>
            </div>
            <div className="client_logo">
                <div> <img src={PhLogo} alt="phlogo" /> </div>
                <div> <img src={PhLogo} alt= "phlogo" /> </div>
                <div> <img src={PhLogo} alt= "phlogo"/></div>
                <div> <img src={PhLogo} alt= "phlogo"/></div>
            </div>
        </div> */}
      <div className="footerone_container">
        <div className="footer_one">
            <div className="footer_one_img">
              <img src={vessel2} alt="construction"/>
            <div className="start_project">
              <div className="start_next">
              <p>Ready to start your </p>
              <p><span>Next project?</span></p>
              </div>
              <div className="footer_button">
                <Link to={"contactus"}>
                  <CustomButton className="footertalk_button" buttonColor="#019cde" buttonHeight="3rem" buttonWidth="10rem">Let&#39;s talk <FontAwesomeIcon icon={faArrowRight} /></CustomButton>
                </Link>
              </div>
            </div>
            </div>
        </div>
        <div className="footer_two">
            <div className="footer_two_one">
                <p>Intercontinental Maritime &amp; Subsea Support Limited <FontAwesomeIcon icon={faCopyright} /> 2022 . All rights reserved.</p>
            </div>
            <div>
                <p>Crafted by <span className="crafted_span">Saina</span></p>
            </div>
        </div>
      </div>
      
    </div>
  );
};


export default Footer;